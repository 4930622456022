import { createStore } from 'vuex'

export default createStore({
  state: {
    serverUrlTemplate: "https://echeck.dev.au.archistar.io",
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})