import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_package = _resolveComponent("x-package")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.xpkgs, (xpkg) => {
    return (_openBlock(), _createBlock(_component_x_package, {
      key: xpkg.fileName,
      xpkg: xpkg,
      class: "item"
    }, null, 8, ["xpkg"]))
  }), 128))
}