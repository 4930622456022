/// <reference no-default-lib="true"/>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import echeckService from '@/service/echeck.service';

@Options({ 
  components: {
    
  },
  props: {
    'xpkg': Object
  },
  methods: {
    download: function(version: any) {
      echeckService.getXPackage(version).then(response => window.open(response.data.downloadUrl))
    },
    clearCache: function(){
      localStorage.removeItem('getXPackagesStamp')
      localStorage.removeItem('getXPackages')
    },
    openLink: function(link: string){
      window.open(link)
    }
  }
})
export default class XPackage extends Vue {
  setup() {
    return { echeckService }
  }
}
</script>

<template>
  <div class="pcard">
    <p v-if="xpkg.latest" class="latest">
      <span>Latest Release</span>
      <span class="clear-cache" v-on:click="clearCache()">
      </span>
    </p>
    <p class="title">
      {{xpkg.fileName}}
    </p>
    <p class="sub-title">
      v{{xpkg.version}} | {{$filters.formatSize(xpkg.sizeBytes)}} | {{$filters.timeSpan(xpkg.releaseDate)}}
    </p>
    <button v-on:click="download(xpkg.version)" class="btn" :class="{'btn-success': !xpkg.latest, 'btn-danger': xpkg.latest }">Download v{{xpkg.version}}</button>
    <img src="images/logo.png" class="logo" v-on:click="openLink('https://archistar.ai')" />
    <img src="images/rhino.png" class="logo rhino-logo" v-on:click="openLink('https://www.rhino3d.com')" />
  </div>
</template>

<style scoped lang="scss">
.componennt {
 background-color: gray;
}

p {
  margin-bottom: 0.8rem;
}

.clear-cache {
  display: block; 
  float: right; 
  width: 1.5rem;
  height: 1.5rem; 
  //background-color: aqua; 
  cursor: pointer;
}

.logo {
  width: 2rem;
  height: 2rem;
  opacity: 0.7;
  float: right;
  cursor: pointer;
}

.rhino-logo {
  margin-right: 0.5rem;
  opacity: 0.5;
}

.pcard {
  text-align: left;
  padding: 1rem;
  background-color: whitesmoke;
  border-radius: 0.1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.3s;
  min-width: 30rem;
  max-width: 30rem;
}

.latest {
  padding-left: 1rem;
  background-color: #dc3545; //#007bff;
  color: white;
  font-family: Merriweather, serif;
  font-size: 32px; 
  //text-shadow: 3px 3px 3px #ababab;
  border-radius: 0.1rem;
}

.pcard:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
}

.title {  
  opacity: 0.9;
  font-family: Merriweather, serif;
  font-family: "Passion One", sans-serif;
  font-weight: 500;
  font-size: 32px; 
  text-shadow: 2px 2px 2px #ababab;
  color: #232324;
}

.sub-title {  
  font-weight: 600;
  color: rgb(101, 99, 99);
}

.btn{
  font-weight: 800;
}

.btn:hover{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>