import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fd3465a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "root" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_package = _resolveComponent("x-package")!
  const _component_x_packages = _resolveComponent("x-packages")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.xpkg)
        ? (_openBlock(), _createBlock(_component_x_package, {
            key: 0,
            xpkg: _ctx.xpkg
          }, null, 8, ["xpkg"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.xpkgs)
        ? (_openBlock(), _createBlock(_component_x_packages, {
            key: 0,
            xpkgs: _ctx.xpkgs
          }, null, 8, ["xpkgs"]))
        : _createCommentVNode("", true)
    ])
  ]))
}