import axios from 'axios'
import store from './../store'

export default new class {
    async getXPackages() {
        const path = '/echeck/swagger/get/rhino/plugin/releases'
        const apiServiceUrl = `${store.state.serverUrlTemplate}${path}?rhino=8`
        
        console.log('echeck-api ➤➤➤ ', apiServiceUrl)
               
        return axios.get(apiServiceUrl, { // return axios.get('/mock/xpackages.json')
            headers: {
            'Content-Type': 'application/json'
            }
        })
    }

    async getXPackage(version: any) {
        const path = '/echeck/swagger/get/rhino/plugin'
        const apiServiceUrl = `${store.state.serverUrlTemplate}${path}?rhino=8&version=${version}&download=true`
        
        console.log('echeck-api ➤➤➤ ', apiServiceUrl)
        
        return axios.get(apiServiceUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}