<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import XPackages from '@/components/XPackages.vue';
import XPackage from '@/components/XPackage.vue';
import echeckService from '@/service/echeck.service';

@Options({
  components: {
    XPackages,
    XPackage
  },
  data () {
    return {
      xpkgs: null,
      xpkg: null
    }
  },
  mounted () {
    const storedData = String(localStorage.getItem('getXPackages'))
    const storedResponse = !storedData || storedData == null || storedData == 'null'  || storedData == '' ? null : JSON.parse(atob(storedData))
    const storedResponseStamp = Number(localStorage.getItem('getXPackagesStamp'))
    const currentTimeStamp = Number(new Date())
    const cacheDuration = currentTimeStamp - storedResponseStamp
    const cacheExpiry = 3600000; // 1hr Local Cache // 60 Min * 60 Sec * 1000

    console.log('cache-expired > ', cacheDuration, "[", cacheExpiry, "] [", cacheDuration >= cacheExpiry, "]")

    if (!storedResponse || cacheDuration >= cacheExpiry) { 
      
      echeckService.getXPackages().then(response => {
        localStorage.setItem('getXPackagesStamp', String(currentTimeStamp))

        localStorage.setItem('getXPackages', btoa(JSON.stringify(response.data)))       
        this.xpkgs = response.data.slice(1)
        console.log('api-call > xpkgs', this.xpkgs)

        this.xpkg = response.data.at(0)
        console.log('api-call > xpkg', this.xpkg)
      })
    }
    else {
      this.xpkgs = storedResponse.slice(1)
      console.log('stored > xpkgs', this.xpkgs)
      
      this.xpkg = storedResponse.at(0)
      console.log('stored > xpkg', this.xpkg)
    }
  },
})
export default class HomeView extends Vue {}
</script>

<template>
  <div class="root">
    <div class="left">
      <x-package :xpkg=xpkg v-if="xpkg" />
    </div>
    <div class="right">
      <x-packages :xpkgs=xpkgs v-if="xpkgs" />
    </div>
  </div>
  <!--<img src="images/rhino.png">
  <div>ECheck Rhino Plugin Packages</div>
  -->

</template>

<style scoped lang="scss">
img {
  width: 3rem; height: 3rem;
}
.root {
  position: absolute;
  background:
    /* On "top" */
    repeating-linear-gradient( 45deg, transparent, transparent 240px, #ccc 240px, #ccc 480px ),
    /* on "bottom" */
    linear-gradient( to bottom, #eee, #999 );
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.left {
  padding: 1rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  //background-color: yellow;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  text-align: right;
}

.right {
  padding: 1rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  //background-color: red;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: left;
  text-align: left;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>