<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import echeckService from '@/service/echeck.service';
import XPackage from '@/components/XPackage.vue';

@Options({
  props: [ 
    'xpkgs'
  ],
  components: {
    XPackage,
  }
})
export default class XPackages extends Vue {
  setup() {
    return { echeckService }
  }
}
</script>

<template>
    <x-package v-for="(xpkg) in xpkgs" :key="xpkg.fileName" :xpkg="xpkg" class="item" />
</template>

<style scoped lang="scss">

.item {

}

.item:not(:last-child) {
  margin-bottom: 1rem;;
}
</style>